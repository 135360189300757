<template>
    <div class="view-app">
        <app-bar :container="false">
            <btn
                @click="$router.push({ name: 'Home' })"
                app
                size="sm"
                color=""
                class="btn-outline-light"
            >
                Home
            </btn>

            <select-input
                v-model="year"
                :choices="[
                    [2020, 2020],
                    [2021, 2021],
                    [2022, 2022],
                    [2023, 2023],
                ]"
            />

            <btn
                @click="gotoAdmin"
                app
                size="sm"
                color=""
                class="btn-outline-light"
            >
                Admin
            </btn>
        </app-bar>
        <div v-if="!year" class="container d-flex justify-content-center">
            <btn
                app
                v-for="yr in availableYears"
                :key="yr"
                @click="
                    year = yr;
                    loadInstances(yr);
                "
                >{{ yr }}</btn
            >
        </div>
        <div v-else>
            <div class="m-3 mb-5">
                <validation-observer ref="validator">
                    <text-input
                        v-model="searchEmail"
                        validation-rules="required|email"
                    >
                        <template v-slot:label> Get forms </template>
                    </text-input>
                </validation-observer>

                <div class="h4 text-danger my-0" v-if="searchError">
                    <i class="fas fa-warning"></i> {{ searchError }}
                </div>
                <div class="d-flex">
                    <div class="pt-2">
                        <btn @click="openDownloadMenu" app size="sm"> GET </btn>
                    </div>
                    <div
                        class="form-check form-check-inline ml-3 search-option"
                    >
                        <div class="custom-control custom-checkbox">
                            <input
                                v-model="ignoreCheckoutOptions"
                                type="checkbox"
                                id="customInlineCheck1"
                                class="custom-control-input"
                            />
                            <label
                                class="custom-control-label"
                                for="customInlineCheck1"
                                >Ignore checkout options
                                <br />
                                <small class="font-italic"
                                    >Ex. to check someone's state return when
                                    they didn't choose to buy a state
                                    return</small
                                >
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="m-3">
                <h3 class="p-0 m-0">8843's to be shipped</h3>
                <table class="table w-100">
                    <tr class="thead-light">
                        <th>Id</th>
                        <th>Email</th>
                        <th>Last Printed</th>
                        <th>Print</th>
                    </tr>
                    <div
                        v-if="!forms.length"
                        class="h3 text-danger text-center"
                    >
                        No 8843's to be shipped
                    </div>
                    <tr v-for="form in forms" :key="form.id">
                        <td>
                            {{ form.id }}
                        </td>
                        <td>
                            {{ form.email }}
                        </td>
                        <td v-if="form.last_printed">
                            {{ form.last_printed }}
                        </td>
                        <td v-else>NULL</td>
                        <td class="h4">
                            <btn
                                class="badge badge-secondary"
                                color=""
                                @click="
                                    getForm(form.id, {
                                        get_instructions: false,
                                    })
                                "
                            >
                                Print
                            </btn>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <modal v-model="downloadModal">
            <template v-slot:title>
                Download forms for {{ searchEmail }}
            </template>
            <template v-slot:body>
                <btn
                    app
                    v-for="ret in availableReturns"
                    :key="ret.name"
                    @click="searchForm(ret)"
                    block
                    :loading="ret.loading"
                    color="info"
                >
                    {{ ret.name }}</btn
                >
                <div class="h4 text-danger mt-3" v-if="downloadMenuError">
                    <i class="fas fa-warning"></i>
                    {{ downloadMenuError }}
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import AppBar from '@/components/app-bar';

export default {
    components: { AppBar },
    data() {
        return {
            forms: [],
            year: 2020,
            availableYears: [2020],
            searchEmail: '',
            downloadModal: false,
            availableReturns: [],
            searchError: '',
            downloadMenuError: '',
            ignoreCheckoutOptions: false,
        };
    },
    computed: {
        adminUrl() {
            if (window.location.hostname == 'localhost') {
                return 'http://127.0.0.1:8000/admin';
            }
            return `https://${window.location.hostname}/admin`;
        },
    },
    created() {
        this.loadInstances();
    },

    watch: {
        year() {
            this.loadInstances();
        },
    },
    methods: {
        gotoAdmin() {
            window.location.replace(this.adminUrl);
        },
        async downloadSearchForm(ret) {
            var type = ret.name;
            ret.loading = true;
            this.downloadMenuError = '';
            this.$store.getters.client
                .get(`/admin-print-forms/`, {
                    params: {
                        email: this.searchEmail,
                        year: this.year,
                        type: type,
                    },
                    responseType: 'arraybuffer',
                })
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: 'application/pdf',
                    });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    var title = '';
                    for (var i = 0; i < this.searchEmail.length; i++) {
                        if (this.searchEmail[i] == '@') {
                            break;
                        }
                        title += this.searchEmail[i];
                    }
                    link.download = `${title}_${this.year}_${type}.pdf`;
                    link.click();
                    URL.revokeObjectURL(link.href);
                    ret.loading = false;
                })
                .catch((err) => {
                    this.downloadMenuError = `An error occurred: ${err.response.data}`;
                    ret.loading = false;
                });
        },

        async openDownloadMenu() {
            await this.$refs.validator.validate();

            if (!this.$refs.validator.flags.valid) return;

            this.searchError = '';

            this.$store.getters.client
                .get('/return-types/', {
                    params: {
                        admin_dashboard: true,
                        year: this.year,
                        email: this.searchEmail,
                        ignore_purchased: this.ignoreCheckoutOptions,
                    },
                })
                .then((response) => {
                    this.availableReturns = [];

                    for (
                        var i = 0;
                        i < response.data.context.returns.length;
                        i++
                    ) {
                        this.availableReturns.push({
                            name: response.data.context.returns[i],
                            loading: false,
                        });
                    }
                    this.downloadModal = true;
                })
                .catch((error) => {
                    this.searchError = error.response.data;
                });
        },

        searchForm(ret) {
            var type = ret.name;

            ret.loading = true;
            this.downloadMenuError = '';
            this.$store.getters.client
                .get(`/admin-print-forms/`, {
                    params: {
                        email: this.searchEmail,
                        year: this.year,
                        type: type,
                    },
                    responseType: 'arraybuffer',
                })
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: 'application/pdf',
                    });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    // const fileName = response.headers["Content-Disposition"].match(
                    // 	/filename=(.*)/
                    // )[1];
                    var title = '';
                    for (var i = 0; i < this.searchEmail.length; i++) {
                        if (this.searchEmail[i] == '@') {
                            break;
                        }
                        title += this.searchEmail[i];
                    }
                    link.download = `${title}_${this.year}_${type}.pdf`;
                    link.click();
                    URL.revokeObjectURL(link.href);
                    ret.loading = false;
                })
                .catch((err) => {
                    this.downloadMenuError = `An error occurred: ${err.response.data}`;
                    ret.loading = false;
                });
        },

        getForm(id, params) {
            return this.$store.getters.client
                .get(`/admin-print-forms/`, {
                    params: {
                        id: id,
                        ...params,
                    },
                    responseType: 'arraybuffer',
                })
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: 'application/pdf',
                    });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    // const fileName = response.headers["Content-Disposition"].match(
                    // 	/filename=(.*)/
                    // )[1];
                    link.download = `${id}_TAXRETURN.pdf`;
                    link.click();
                    URL.revokeObjectURL(link.href);
                });
        },
        loadInstances() {
            this.$store.getters.client
                .get('/print-shipping-forms/', { params: { year: this.year } })
                .then((response) => {
                    this.forms = response.data;
                });
        },
    },
};
</script>
